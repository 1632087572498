<template>
    <div>
        <h1 class="page__title">Создать отзыв</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-6">
                    <label for="form-user_name" class="form-label">
                        Имя пользователя
                        <sup v-if="v$.form.user_name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.user_name.$errors.length" for="form-user_name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.user_name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-user_name"
                        v-model="form.user_name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.user_name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-published" class="form-label">Опубликовано</label>
                    <select id="form-published" v-model="form.published" class="form-select">
                        <option :value="true">Опубликовано</option>
                        <option :value="false">Не опубликовано</option>
                    </select>
                </div>

                <div class="col-span-6">
                    <label for="form-cruise_title" class="form-label">Название круиза</label>
                    <input
                        id="form-cruise_title"
                        v-model="form.cruise_title"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-cruise_date" class="form-label">Дата круиза</label>
                    <input
                        id="form-cruise_date"
                        v-model="form.cruise_date"
                        type="date"
                        class="form-control w-full"
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-cruise_date" class="form-label">Дата отзыва</label>
                    <input
                        id="form-cruise_date"
                        v-model="form.review_date"
                        type="date"
                        class="form-control w-full"
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Изображения</label>
                    <UploadMultiImageNew v-model="form.images" />
                </div>

                <div class="col-span-12">
                    <label for="form-ship" class="form-label">Теплоход</label>
                    <Multiselect
                        id="form-ship"
                        v-model="selectShip"
                        track-by="title"
                        value-prop="id"
                        label="title"
                        mode="single"
                        :filter-results="false"
                        :resolve-on-load="false"
                        :object="true"
                        :searchable="true"
                        :can-clear="true"
                        :value="[]"
                        placeholder="Не выбрано"
                        no-results-text="Не найдено"
                        no-options-text="Не найдено"
                        :delay="0"
                        :options="
                            async function (query) {
                                return await fetchSearchParamsShip(query);
                            }
                        "
                    />
                </div>

                <div class="relative col-span-12" :class="{ 'form-editor-error': v$.form.review.$errors.length }">
                    <label for="form-review" class="form-label">
                        Текст отзыва
                        <sup v-if="v$.form.review.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.review.$errors.length" for="form-review" class="form-editor-text-error">
                        {{ getOverrideMessage(v$.form.review.$errors[0].$message) }}
                    </label>
                    <CustomWyswig id="form-review" v-model="form.review" />
                </div>

                <div class="relative col-span-12">
                    <label for="form-rating" class="form-label">
                        Оценка
                        <sup v-if="v$.form.rating.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.rating.$errors.length" for="form-rating" class="form-image-text-error">
                        {{ getOverrideMessage(v$.form.rating.$errors[0].$message) }}
                    </label>

                    <ReviewRating id="form-rating" v-model="form.rating" />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'reviews' }" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
                    <button type="button" class="btn btn-primary mb-2 mr-2" @click="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save()">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import { reviewsMixin } from '@/mixins/form/reviews-mixin';
import Preloader from '@/components/preloader/Main';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import ReviewRating from '@/components/base/ReviewRating';
import Multiselect from '@vueform/multiselect';
import UploadMultiImageNew from '@/components/upload-image/UploadMultiImageNew.vue';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'Create',
    components: { UploadMultiImageNew, Preloader, CustomWyswig, Multiselect, ReviewRating },
    mixins: [errorResponse, formPreparation, reviewsMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.$store.commit('main/setPageHeader', null);
    },
    validations() {
        return {
            form: {
                user_name: { required },
                review: { required },
                rating: { required },
            },
        };
    },
    methods: {
        async save(flag = true) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.ship_id = this.selectShip ? this.selectShip.id : null;
            if (!this.form.cruise_date) this.form.cruise_date = dayjs().format('YYYY-MM-DD');
            if (!this.form.review_date) this.form.review_date = dayjs().format('YYYY-MM-DD');
            const form = this.formPreparation({ imagesAsObject: true });

            this.axios
                .post('/reviews', form)
                .then(({ data }) => {
                    this.loading = false;
                    if (flag) {
                        this.$router.push({ name: 'review-edit', params: { id: data.data.id } });
                        this.$notify(data.message);
                    } else {
                        this.$router.push({ name: 'reviews' });
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style src="../../../node_modules/@vueform/multiselect/themes/default.css"></style>
<style></style>
