<template>
    <div class="upload-image">
        <div class="input-wrap" @click="$refs.uploadImageInput.click()">
            <span class="btn btn-primary inline-block">Загрузить изображения</span>
            <input
                ref="uploadImageInput"
                type="file"
                :accept="acceptExtensions"
                multiple
                class="input"
                @change="uploadFile"
            />
        </div>
        <div class="preview grid grid-cols-12 gap-6">
            <div v-for="(image, key) in modelValue" :key="key" class="item col-span-3 flex flex-col justify-between">
                <img v-if="image.url" :src="image.url" :alt="image.url" class="max-h-[300px]" />
                <img v-else-if="image.local_url" :src="image.local_url" :alt="image.local_url" class="max-h-[300px]" />

                <span @click="remove(image, key)"><XCircleIcon /></span>

                <div v-if="previewImages[key]" class="flex flex-col gap-3 mt-2 border-t border-[#cbd5e0] pt-4">
                    <div>
                        <label class="form-label">Заголовок изображения</label>
                        <input
                            :value="previewImages[key].title"
                            type="text"
                            class="form-control w-full"
                            placeholder=""
                            autocomplete="off"
                            @input="(e) => onChangeTitle(e, key)"
                        />
                    </div>

                    <div>
                        <label class="form-label">Альт изображения</label>
                        <input
                            :value="previewImages[key].alt"
                            type="text"
                            class="form-control w-full"
                            placeholder=""
                            autocomplete="off"
                            @input="(e) => onChangeAlt(e, key)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadMultiImageNew',
    props: {
        srcKey: {
            type: String,
            default: 'image',
        },
        modelValue: Array,
        delete: Array,
    },
    data() {
        return {
            acceptExtensions: 'image/jpeg, image/png, image/gif, image/webp',
            previewImages: [],
        };
    },
    watch: {
        modelValue: function (val) {
            this.previewImages = val;
        },
    },
    methods: {
        uploadFile(e) {
            for (const file of e.target.files) {
                const blob = window.URL.createObjectURL(new Blob([file]));
                this.previewImages.push({ local_url: blob, file: file });
            }

            this.$emit(`update:modelValue`, this.previewImages);
        },
        remove(image, key) {
            this.previewImages.splice(key, 1);

            if (image.id) {
                this.delete.push(image.id);
                this.$emit(`update:delete`, this.delete);
            } else {
                window.URL.revokeObjectURL(image.local_url);
                this.$emit(`update:modelValue`, this.modelValue);
            }
        },
        onChangeTitle(e, key) {
            const modelValue = [...this.modelValue];
            modelValue[key].title = e.target.value;
            this.$emit(`update:modelValue`, modelValue);
        },
        onChangeAlt(e, key) {
            const modelValue = [...this.modelValue];
            modelValue[key].alt = e.target.value;
            this.$emit(`update:modelValue`, modelValue);
        },
    },
};
</script>

<style scoped lang="scss">
.upload-image {
    box-sizing: border-box;
    min-height: 150px;
    background: white;
    padding: 20px 20px;
    border: 1px dashed #cbd5e0;
}

.input-wrap {
    padding-bottom: 15px;
}

.input {
    visibility: hidden;
}

.preview {
    border-top: 1px solid #cbd5e0;
    height: auto;
    width: 100%;
    max-height: 480px;
    overflow: hidden;
    overflow-y: auto;

    .item {
        margin: 10px;
        position: relative;

        span {
            position: absolute;
            right: -5px;
            top: -5px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
        }
    }
}
</style>
